<template>
  <div>
    <!-- banner弹窗 -->

    <div
      style="padding: 0 22px; margin-top: 20px"
      v-for="(item, index) in scriptCoverList"
      :key="index"
    >
      <div>
        <div class="flex-around">
          <div class="flex">
            <div class="first-title" style="padding: 10px 0">
              图位{{ index + 1 }}:
            </div>
          </div>
          <div>
            <el-button
              type="text"
              style="width: 48px"
              :style="{ color: index === 0 ? '#999' : '#666' }"
              @click="moveBanner(index, 'toFirst')"
              :disabled="index === 0"
              >置顶</el-button
            >
            <el-button
              type="text"
              style="width: 48px; margin-left: 20px"
              @click="moveBanner(index, 'pre')"
              :style="{ color: index === 0 ? '#999' : '#666' }"
              :disabled="index === 0"
              >上移</el-button
            >
            <el-button
              type="text"
              style="width: 48px; color: #666"
              @click="moveBanner(index, 'next')"
              :style="{
                color: scriptCoverList.length - 1 === index ? '#999' : '#666',
              }"
              :disabled="scriptCoverList.length - 1 === index"
              >下移</el-button
            >
            <el-button
              type="danger"
              size="mini"
              style="margin-left: 20px"
              @click="delBanner(index)"
              plain
              >删除图位</el-button
            >
          </div>
        </div>
        <div class="flex flex-ac" style="margin-top: 10px">
          <div class="first-title">关联剧本:</div>
          <div>
            <el-select
              v-model="item.skipUrl"
              @change="scenarioChange($event, item)"
              size="mini"
              placeholder="请选择"
            >
              <el-option
                v-for="scenarioitem in scenarioList"
                :key="scenarioitem.id"
                :label="scenarioitem.scenarioName"
                :value="scenarioitem.id"
              >
              </el-option>
            </el-select>
          </div>
          <choice-image
            style="margin-left: 10px"
            @surePush="changeImage($event, index)"
            :viewUrl="item.bannerPicturePath"
            text="图片预览"
            type="text"
          />
        </div>
        <div class="flex flex-ac" style="margin-top: 10px">
          <div class="first-title">转跳页面:</div>
          <div v-if="getName(item.skipUrl)" class="font-s14" style="color: #33333">
            &lt;&lt;{{ getName(item.skipUrl) }}&gt;&gt;剧本详情页
          </div>
          <div v-else class="tip" style="">请先关联选择剧本</div>
        </div>
      </div>
      <el-divider></el-divider>
    </div>
    <div style="width: 100%; position: relative; margin-top: 10px">
      <el-button
        class="center"
        size="mini"
        icon="el-icon-plus"
        @click="addBanner"
        >添加图位</el-button
      >
    </div>
  </div>
</template>

<script>
import choiceImage from "@/components/common/choiceImage";
import { mapState } from "vuex";
export default {
  props: {
    scriptCoverList: {
        type: Array
    },
  },
  data() {
    return {
      bannerIndex: 0,
      scenarioModel: {
        isAudit: 0,
        searchName: "",
        time: "",
        page: 1,
        limit: 50,
      },
      bannerItem: {
        id: "",
        bannerPictureId: "", //banner图片ID
        bannerPicturePath: "", //banner图片地址
        skipUrl: "", //跳转路由
        skipType: 1, //跳转类型
        popupPictureId: "", //弹窗图片ID
        popupPicturePath: "", //弹窗图片地址
        popupContent: "", //弹窗内容
        sort: "",
        scenarioId: "",
        scenarioName: "",
        scenarioScore: 0,
        time: new Date().getTime(),
      },
    };
  },
  components: {
    choiceImage,
  },
  computed: {
    ...mapState(["scenarioList"]),
  },
  mounted() {
     this.$store.dispatch("getScenarioList");
  },
  methods: {
    getName(v) {
     return this.scenarioList.find(item => item.id === v)?.scenarioName || ''
    },
    scenarioChange(e, item) {
      const { scenarioName, scenarioScore } = this.scenarioList.find((item) => item.id == e);
      item.scenarioName = scenarioName;
      item.scenarioScore = scenarioScore;
    },
    // 页面图
    changeImage(v, index) {
      const item = this.scriptCoverList[index];
      item.bannerPicturePath = v.picturePath;
      item.bannerPictureId = v.id;
    },
    addBanner() {
      const newItem = JSON.parse(JSON.stringify(this.bannerItem));
      this.scriptCoverList.push(newItem);
    },
    delBanner(index) {
      this.$confirm("是否删除此banner?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.scriptCoverList.splice(index, 1);
      });
    },
    moveBanner(index, movetype) {
      //   const arr = this.model[this.curTabIndex].exhibitionBannerRelevanceVOList;
      const arr = this.scriptCoverList;
      this._move(arr, movetype, index);
    },
    _move(arr, movetype, index) {
      const [item] = arr.splice(index, 1);
      if (movetype !== "toFirst") {
        arr.splice(movetype === "pre" ? index - 1 : index + 1, 0, item);
        return;
      }
      arr.unshift(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-img-choice {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #c5c0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #333333;
  font-family: "SourceHanSansCN-Regular, SourceHanSansCN";
}
.flex-around {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flex {
  display: flex;
}
.flex-start {
  display: flex;
  align-items: center;
}
.center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 100px;
}
.first-title {
  color: #333333;
  font-size: 14px;
  margin-right: 20px;
}
.tip {
  color: #999999;font-size: 14px;
}
.item-image {
  width: 149px;
  height: 56px;
  background: rgba(236, 234, 250, 1);
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
  cursor: pointer;
  .left {
    position: absolute;
    top: -5px;
    left: 0;
  }
  .right {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 54px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    border-radius: 4px;
  }
  .item-image-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    color: #fff;
  }
}
v-deep .el-textarea__inner {
  margin: 0;
}
v-deep .suffix {
  background: #333333;
}
</style> 