<template>
  <div>
    <!-- banner弹窗 -->
    <choice-image
      v-show="false"
      @surePush="changeBannerImage"
      ref="bannerImgComponent"
    />
    <div
      style="padding: 0 22px; margin-top: 30px"
      v-for="(item, index) in bannerList"
      :key="`b${index}`"
    >
      <div>
        <div class="flex-around">
          <div class="flex">
            <div class="first-title" style="padding: 10px 0">
              banner{{ index + 1 }}:
            </div>
            <div
              v-for="(imgItem, i) in changeImg"
              :key="i"
              class="item-image flex-cloumn flex-c mb-10 mr-4"
              @click="choseBannerImg(index, i)"
            >
              <!-- 底图 -->
              <img
                v-if="item[imgItem[1]]"
                style="width: 100%; height: 100%; border-radius: 4px"
                :src="item[imgItem[1]]"
              />
              <div v-else class="banner-img-choice">
                <span>选择图片 +</span>
              </div>
              <el-button
                class="banner-img-leftbtn mt-3"
                :class="i === 0 ? 'banner-img-tip' : ''"
                type="primary"
                size="mini"
                >{{ imgItem[2] }}</el-button
              >
              <el-button
                @click.stop="removeImg(item, i)"
                v-if="i !== 0"
                class="banner-img-rightbtn mt-3"
                type="text"
                size="mini"
                >清除图片</el-button
              >
            </div>
          </div>
          <div>
            <el-button
              type="text"
              style="width: 48px"
              :style="{ color: index === 0 ? '#999' : '#666' }"
              @click="moveBanner(index, 'toFirst')"
              :disabled="index === 0"
              >置顶</el-button
            >
            <el-button
              type="text"
              style="width: 48px; margin-left: 20px"
              @click="moveBanner(index, 'pre')"
              :style="{ color: index === 0 ? '#999' : '#666' }"
              :disabled="index === 0"
              >上移</el-button
            >
            <el-button
              type="text"
              style="width: 48px; color: #666"
              @click="moveBanner(index, 'next')"
              :style="{
                color: bannerList.length - 1 === index ? '#999' : '#666',
              }"
              :disabled="bannerList.length - 1 === index"
              >下移</el-button
            >
            <el-button
              type="danger"
              size="mini"
              style="margin-left: 20px"
              @click="delBanner(index)"
              plain
              >删除图片</el-button
            >
          </div>
        </div>
        <div class="flex" style="margin-top: 10px">
          <div class="first-title mt-1">转跳页面:</div>
          <div style="flex: 1">
            <div class="flex-ac">
              <el-radio
                style="margin-right: 0"
                v-model="item.skipType"
                :label="1"
              >
                <span v-show="false"></span>
              </el-radio>
              <div style="width: 110px" class="font-2 mr-2">剧本详情页</div>
              <el-select
                v-model="item.skipScenarioUrl"
                size="mini"
                placeholder="请选择"
              >
                <el-option
                  v-for="scenarioitem in scenarioList"
                  :key="scenarioitem.id"
                  :label="`《${scenarioitem.scenarioName}》剧本详情页`"
                  :value="scenarioitem.id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="flex-ac">
              <el-radio
                style="margin-right: 0"
                v-model="item.skipType"
                :label="2"
              >
                <span v-show="false"></span>
              </el-radio>
              <div  style="width: 110px"  class="font-2 mr-2">小程序外部链接</div>
              <el-input
                size="mini"
                style="width: 340px; margin-top: 8px"
                placeholder="（例如：公众号推文）"
                v-model="item.skipOutLink"
              ></el-input>
            </div>

              <div class="flex-ac">
              <el-radio
                style="margin-right: 0"
                v-model="item.skipType"
                :label="3"
              >
                <span v-show="false"></span>
              </el-radio>
              <div  style="width: 110px"  class="font-2 mr-2">小程序内部链接</div>
              <el-input
                size="mini"
                style="width: 340px; margin-top: 8px"
                placeholder="（例如：营销链接）"
                v-model="item.skipInnerLink"
              ></el-input>
            </div>

            <!-- <div class="flex-start" style="margin-top: 10px">
              <el-radio v-model="item.skipType" :label="2">弹窗:</el-radio>
              <choice-image
                @surePush="changeImage($event, index)"
                :viewUrl="item.popupPicturePath"
              />
            </div>
            <el-input
              type="textarea"
              style="width: 560px; margin: 8px 85px"
              :rows="2"
              placeholder="请输入弹窗文字内容"
              v-model="item.popupContent"
            ></el-input> -->
          </div>
        </div>
         <el-divider></el-divider>
      </div>
    </div>
    <div style="width: 100%; position: relative; margin-top: 10px">
      <el-button
        class="center"
        size="mini"
        icon="el-icon-plus"
        @click="addBanner"
        >添加图位</el-button
      >
    </div>
  </div>
</template>

<script>
import choiceImage from "@/components/common/choiceImage";
import { mapState } from "vuex";
export default {
  props: {
    bannerList: {
      type: Array,
    },
  },
  data() {
    return {
      bannerIndex: 0,
      scenarioModel: {
        isAudit: 0,
        searchName: "",
        time: "",
        page: 1,
        limit: 50,
      },
      bannerItem: {
        id: "",
        hide: false,
        bannerPictureId: "", //banner图片ID
        bannerPicturePath: "", //banner图片地址
        skipUrl: "", //跳转路由
        skipType: 1, //跳转类型
        popupPictureId: "", //弹窗图片ID
        popupPicturePath: "", //弹窗图片地址
        popupContent: "", //弹窗内容
        sort: "",
        scenarioName: "",
        scenarioId: "",
        threeBannerPictureId: 0,
        threeBannerPicturePath: 0,
        twoBannerPictureId: 0,
        twoBannerPicturePath: 0,
        time: new Date().getTime(),
      },
      changeImgType: "",
      changeImg: [
        ["bannerPictureId", "bannerPicturePath", "底图图片"],
        ["twoBannerPictureId", "twoBannerPicturePath", "二层图片"],
        ["threeBannerPictureId", "threeBannerPicturePath", "三层图片"],
      ],
    };
  },
  components: {
    choiceImage,
  },
  computed: {
    ...mapState(["scenarioList"]),
  },
  mounted() {
    this.$store.dispatch("getScenarioList");
  },
  methods: {
    // 页面图
    changeImage(v, index) {
      const item = this.bannerList[index];
      item.popupPicturePath = v.picturePath;
      item.popupPictureId = v.id;
    },
    // banner图
    changeBannerImage(v) {
      const item = this.bannerList[this.bannerIndex];
      // item.bannerPicturePath = v.picturePath;
      // item.bannerPictureId = v.id;
      const [id, path] = this.changeImg[this.changeImgType];
      item[path] = v.picturePath;
      item[id] = v.id;
    },
    choseBannerImg(index, changeImgType) {
      this.changeImgType = changeImgType;
      this.bannerIndex = index;
      this.$refs.bannerImgComponent.showDialog();
    },
    removeImg(item, i) {
      this.$confirm("是否清除此图片?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const [id, path] = this.changeImg[i];
        item[path] = 0;
        item[id] = 0;
      });
    },
    addBanner() {
      const newItem = JSON.parse(JSON.stringify(this.bannerItem));
      this.bannerList.push(newItem);
    },
    delBanner(index) {
      this.$confirm("是否删除此banner?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.bannerList.splice(index, 1);
      });
    },
    // 移动banner
    moveBanner(index, movetype) {
      //   const arr = this.model[this.curTabIndex].exhibitionBannerRelevanceVOList;
      const arr = this.bannerList;
      this._move(arr, movetype, index);
    },
    _move(arr, movetype, index) {
      const [item] = arr.splice(index, 1);
      if (movetype !== "toFirst") {
        arr.splice(movetype === "pre" ? index - 1 : index + 1, 0, item);
        return;
      }
      arr.unshift(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-img {
  &-choice {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #c5c0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #333333;
    font-family: "SourceHanSansCN-Regular, SourceHanSansCN";
  }
  &-leftbtn {
    position: absolute;
    bottom: -15px;
    left: 0%;
    transform: translate(0%, 100%);
  }
  &-rightbtn {
    position: absolute;
    bottom: -15px;
    right: 0%;
    transform: translate(0%, 100%);
  }
  &-tip::after {
    content: "*";
    position: absolute;
    font-size: 20px;
    top: 50%;
    right: -20%;
    transform: translate(0%, -50%);
    color: red;
  }
}

.flex-around {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flex {
  display: flex;
}
.flex-start {
  display: flex;
  align-items: center;
}
.center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 100px;
}
.first-title {
  color: #333333;
  font-size: 14px;
  margin-right: 20px;
}
.item-image {
  width: 149px;
  height: 56px;
  background: rgba(236, 234, 250, 1);
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
  cursor: pointer;
  .left {
    position: absolute;
    top: -5px;
    left: 0;
  }
  .right {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 54px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    border-radius: 4px;
  }
  .item-image-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    color: #fff;
  }
}
v-deep .el-textarea__inner {
  margin: 0;
}
</style> 